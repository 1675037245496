import React from "react"
import classNames from "classnames"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import styles from "./terms-and-conditions.module.css"

const TermsAndConditionsPage = () => (
  <SiteLayout>
    <SEO title="Terms and condition" />
    <section className={styles.sectionWrapper}>
      <div className={classNames("container", styles.container)}>
        <h1 className={styles.heading}>
          <strong>"SuburbGrowth" Website Terms and Conditions</strong>
        </h1>
        <p>
          This Website is owned and operated by Select Residential Property Pty
          Ltd (ACN 130 236 743).
        </p>

        <p>
          In these Terms, “you” means you, the Customer or site visitor and “we”
          and “us” means “SuburbGrowth.com.au”.
        </p>

        <h4>
          <strong>Website Services</strong>
        </h4>

        <p>
          “SuburbGrowth.com.au” is an application website that to provide
          customers quality data analysis, including but not limited to capital
          growth predictions and forecasts to help better educate them on
          certain property markets, it achieves this through an information
          service provided which harnesses big data technology, subject matter
          expertise, data capture and an online user experience approach.
        </p>

        <p>
          The Property Prediction Report, calculators and other research and
          data tools on this website are provided for your information and to
          illustrate suburb scenarios by either house or unit markets where the
          data allows for a capital growth prediction for the next three years
          is possible base on our algorithm. The main objective of the
          “SuburbGrowth.com.au” reports is to provide a broad insight into the
          location (suburb) that may assist you in determining a view of the
          suburb future growth potential. SuburbGrowth is for private,
          non-commercial use only and should never be solely or exclusively
          relied upon for any property decision undertaken. Rather is should
          form part of extensive and thorough investigations using other
          resources before making any decision, remembering that individual
          property within a suburb will more than likely perform different to a
          suburb as a whole. The owner and agents for the owner are unable to
          accept any liability or responsibility whatsoever for any error or
          omission or any loss or damage of any kind sustained by any person or
          entity arising from the use of this information. It is recommended
          that users always seek independent professional advice relevant to
          their specific circumstances before acting on the information in those
          reports, calculator and tools.
        </p>

        <p>
          Access to and use of the Website and the products and services
          available through the Website are subject to the following Terms which
          may be updated by us from time to time.
        </p>

        <h4>
          <strong>1.</strong>
          <strong>Your acceptance</strong>
        </h4>

        <span>(a)</span>
        <p>
          These are the terms on which SuburbGrowth.com.au permits users to
          access and use the “SuburbGrowth.com.au” website (Website) including
          using the services and functionality made available through the
          Website, viewing Content provided by us and third parties,
          communicating with “SuburbGrowth.com.au” and placing Requests.
        </p>

        <span>(b)</span>
        <p> You agree to be bound by these Terms by:</p>
        <span>(i)</span>
        <p>using, browsing or accessing any part of the Website; </p>
        <span>(ii)</span>
        <p>registering as a Customer on the Website; or</p>
        <span>(iii)</span>
        <p>submitting a Request.</p>
        <span>(c)</span>
        <p>
          If you do not agree to these Terms, you are not permitted to access
          and use this Website and you should immediately cease using this
          Website.
        </p>

        <span>(d)</span>
        <p>
          “SuburbGrowth.com.au” may from time to time review and update these
          Terms to take account of new laws, regulations, products or
          technology. Your use of the Website will be governed by the most
          recent Terms posted on the Website. By continuing to use the Website,
          you agree to be bound by the most recent Terms. It is your
          responsibility to check the Website regularly for updated versions of
          the Terms. You will not be separately notified of the changes.
        </p>

        <span>(e)</span>
        <p>
          The Website is subject to change at any time without notice and may
          contain errors.
        </p>

        <h4>
          <strong>2.</strong>
          <strong>Customer registration and passwords</strong>
        </h4>

        <span>(a)</span>
        <p>
          Users may browse or access the Website to review available products
          and services. Should you wish to submit a Request you will be required
          to register your details.
        </p>

        <span>(b)</span>
        <p> We may use your registration information to contact you.</p>

        <h4>
          <strong>3.</strong>
          <strong>Purchase terms</strong>
        </h4>

        <span>(a)</span>
        <p>
          “SuburbGrowth.com.au” warrant that the provision of services are
          provided with the best possible skill and technical knowledge and that
          the information provided are for the purpose of providing education
          and board insights at the suburb level for either the house or units
          markets within that suburb.
        </p>
        <span>(b)</span>
        <p>
          “SuburbGrowth.com.au” makes no representations or warranties as to the
          completeness or adequacy or accuracy of Information provided and
          expressly disclaims liability for any errors or omissions or delays in
          this information.
        </p>
        <span>(c)</span>
        <p>
          Information is supplied upon the condition that the persons receiving
          the same will make further research enquiries about the location
          before making their own determination as to its suitability for their
          purposes prior to use or in connection with the making of any
          decision. Any use of this website, or information we provide to you,
          is at your own risk.
        </p>
        <span>(d)</span>
        <p>
          “SuburbGrowth.com.au” along with its directors, employees, associates
          or other representatives and its Affiliates along with its directors,
          employees, associates or other representatives shall not be liable for
          damages or injury arising out of or in connection with the use of the
          Website or its non-use including non-availability, compensatory,
          direct, indirect or consequential damages, loss of data, income or
          profit, loss of or damage to property (including without limitation
          loss of profits, loss or corruption of data, loss of goodwill, work
          stoppage, computer failure or malfunction, or interruption of
          business; under any contract, negligence, strict liability or other
          theory arising out of or relating in any way to the Website,
          site-related services, or any products or services and claims of third
          parties damages or injury caused by any performance, failure of
          performance, error, omission, interruption, deletion, defect, delay in
          operation or transmission, computer virus, communications line
          failure, theft or destruction or unauthorized access to, alteration
          of, or use of information, whether resulting, in whole or in part,
          from or relating to any of the services offered or information
          provided by this Website.
        </p>
        <span>(e)</span>
        <p>
          Our services are not intended to be any form of an investment advice
          or information and has not been registered under any securities law of
          any foreign jurisdiction and is only for the information of any person
          in any jurisdiction where it may be lawful to provide such
          information, otherwise the same shall not be considered an
          information. The distribution of this service or content in other
          jurisdictions may be restricted by law and the persons who access the
          Service should inform themselves about, and observe, any such
          restrictions.
        </p>
        <span>(f)</span>
        <p>
          Use of the website and its contents is at any persons, including a
          Customer′s, own risk. The data and information provided on the
          website, and in our reports, is not advice, professional or otherwise,
          and should not be relied upon as such. Investors must make their own
          investment decisions based on their own specific investment objectives
          and financial position and using such independent professional
          advisors as they believe necessary.
        </p>
        <span>(g)</span>
        <p>
          “SuburbGrowth.com.au” will not be liable to you or any third party for
          any damages of any kind, including but not limited to, direct,
          indirect, incidental, consequential or punitive damages, arising from
          or connected with the site, including but not limited to, your use of
          this site or your inability to use the site.
        </p>
        <span>(h)</span>
        <p>
          It is expressly agreed by the User that exclusive jurisdiction for any
          dispute with “SuburbGrowth.com.au” resides in the courts of Victoria
          in Australia, and the User expressly consents to the exercise of
          jurisdiction in the courts of Victoria, in connection with any such
          dispute. These terms shall be governed by and construed in accordance
          with the laws of Australia.
        </p>
        <span>(i)</span>
        <p>
          The User may use the information as a resource only to further your
          own research on whether to SuburbGrowth.com.au a residential property.
        </p>
        <span>(j)</span>
        <p>
          Past performance may not be an indicator of future performance or
          returns.
        </p>
        <span>(k)</span>
        <p>These terms are subject to change without prior notice.</p>
        <span>(l)</span>
        <p>
          The “SuburbGrowth.com.au” reports is for information purposes only and
          is not specific to any individual property. It has not been prepared
          with regard to the specific investment objectives, financial
          situations and/or particular needs of any specific person who may
          receive this report. Investors should seek advice from Financial
          Advisors/Certified Financial Planners/Financial Experts before making
          important investment decisions, or acting on any investment strategies
          that may have been discussed or recommended in this report. No
          reliance may be placed for any purpose whatsoever on the information
          contained in this report or on its completeness.
        </p>
        <span>(m)</span>
        <p>
          “SuburbGrowth.com.au” or any of its associate companies do not
          guarantee the accuracy or correctness of the information provided in
          the report made available or that any forecasts or projections made in
          this report will be exactly realised, understanding that any short or
          medium term prediction or forecast is subject to many changing
          variables which can and will impact such estimates of capital growth
          in this case. As such the growth projections with the reports should
          be use as a board guide only and market price accordingly.
          “SuburbGrowth.com.au” does not accept any responsibility for any
          errors whether caused by negligence or otherwise or for any loss or
          damage incurred by anyone in reliance on anything that is included in
          the report. The recipient shall solely be responsible for losses,
          damages, costs and other consequences resulting directly or indirectly
          from using any information made available in the report.
        </p>
        <span>(n)</span>
        <p>
          The information in this report reflects prevailing conditions as of
          date of publication and is subject to change from time to time. Any
          facts or figures mentioned in this report are merely indicative and
          recipients should obtain correct facts and figures before making any
          investment decisions.
        </p>
        <span>(o)</span>
        <p>
          The information contained in this report may not be transmitted to,
          copied or distributed, in part or in whole, to any other person or to
          the media or reproduced in any form, without the prior written consent
          of “SuburbGrowth.com.au”.
        </p>

        <h4>
          <strong>4.</strong>
          <strong>Product and Service information and availability</strong>
        </h4>
        <span>(a)</span>
        <p>
          The listing of any particular product or service on our Website is for
          information and research purposes only. “SuburbGrowth.com.au” does not
          warrant or make any representations in relation to any products or
          services and you will need to ensure any product or service you
          Request meets your specific requirements.
        </p>
        <span>(b)</span>
        <p>
          We are not responsible or liable for any loss or damage you or any
          third party may suffer or incur in connection with any product or
          service you obtain after using this Website or for any acts,
          omissions, errors or defaults of any Supplier or third party in
          connection with that product or service.
        </p>
        <span>(c)</span>
        <p>
          “SuburbGrowth.com.au” has the right, but not the obligation, to
          monitor any information, comment, content, communication, advice,
          text, or other material (Content) made available on the Website.
          “SuburbGrowth.com.au” reserves the right, in its absolute discretion,
          to block, modify or remove any Content contained on the Website
          without notice, and will not be liable in any way for possible
          consequences of such actions.
        </p>

        <h4>
          <strong>5.</strong>
          <strong>Service Fees/Commissions: </strong>
        </h4>
        <p>
          We do not receive commissions or referral payments from any third
          party vendor. In the event a third party does pay a referral fee or
          commission to us, outside of what is stated above, these monies will
          be passed onto you in full.
        </p>
        <h4>
          <strong>6.</strong>
          <strong>Links</strong>
        </h4>

        <span>(a)</span>
        <p>
          The Website may contain links to other web sites.
          “SuburbGrowth.com.au” provides those links as a ready reference for
          searching for third party goods and services on the internet and not
          as an endorsement of those web sites, their operators, the goods,
          services or content that they describe.
        </p>

        <span>(b)</span>
        <p>
          Other web sites which are linked to the Website are not covered by
          these Terms, and may have their own terms and conditions and privacy
          policy. If you choose to access these linked sites, you do so at your
          own risk. “SuburbGrowth.com.au” is not responsible for and will not be
          liable in respect of the content or operation of those web sites or
          any of the goods, services or content that they describe.
          “SuburbGrowth.com.au” is not responsible for and will not be liable in
          respect of any incorrect link to an external web site.
        </p>

        <span>(c)</span>
        <p>
          You are not permitted to frame or link the Website without
          “SuburbGrowth.com.au’s express written permission.
        </p>

        <h4>
          <strong>7.</strong>
          <strong>Access and communication</strong>
        </h4>

        <span>(a)</span>
        <p>
          Subject to the consumer guarantees provided for in consumer protection
          legislation (including the Australian Consumer Law),
          “SuburbGrowth.com.au” does not warrant that you will have continuous
          access to the Website. “SuburbGrowth.com.au” will not be liable in the
          event that the Website is unavailable to you due to computer downtime
          attributable to malfunctions, upgrades, preventative or remedial
          maintenance activities or interruption in telecommunications supply.
        </p>

        <span>(b)</span>
        <p>
          “SuburbGrowth.com.au” does not guarantee the delivery of
          communications over the internet as such communications rely on third
          party service providers. Electronic communication (including
          electronic mail) is vulnerable to interception by third parties and
          “SuburbGrowth.com.au” does not guarantee the security or
          confidentiality of these communications or the security of the
          Website.
        </p>

        <span>(c)</span>
        <p>
          “SuburbGrowth.com.au” does not provide, and has no control over,
          communications, networks or services, the internet or other technology
          required or used across the Website and accepts no responsibility for
          any direct or indirect loss in any form associated with them, whether
          due to congestion, technical malfunction, viruses or otherwise.
        </p>

        <span>(d)</span>
        <p>
          Details contained on the Website relating to goods and services have
          been prepared in accordance with Australian law and may not satisfy
          the laws of another country. “SuburbGrowth.com.au” does not warrant
          that:
        </p>
        <span>(i)</span>
        <p>the goods or services available on this Website; or</p>
        <span>(ii)</span>
        <p>
          the Website and its Content, comply with the laws of any other
          country. It is your responsibility to determine whether the goods or
          services comply with the laws of your jurisdiction.
        </p>
        <span>(e)</span>
        <p>
          If you access and use this Website or its Content from outside
          Australia or transact on this Website you do so at your own risk.
        </p>

        <h4>
          <strong>8.</strong>
          <strong>Our commitment to protect your privacy</strong>
        </h4>
        <p>
          We understand how important it is to protect your personal
          information. We recognise that any personal information we collect
          about you will only be used for the purposes we have collected it or
          as allowed by law. It is important to us that you are confident that
          any personal information you provide will be treated in a way that
          ensures you full and proper protection. Our commitment in respect of
          personal information is to abide by the Australian Privacy Principles
          for the protection of personal information, as set out in the Privacy
          Act and any other relevant laws. “SuburbGrowth.com.au” may in its
          absolute discretion choose to provide the name and email address of
          customers to its affiliated companies. The provision of this
          information will be for marketing purposes only.
        </p>
        <h4>
          <strong>9.</strong>
          <strong>Intellectual property rights</strong>
        </h4>

        <span>(a)</span>
        <p>
          All intellectual property rights, including copyright and patents, in
          the Website, “SuburbGrowth.com.au”'s services, and all components of
          them are owned or licensed by “SuburbGrowth.com.au” or any of its
          related entities. You must not copy, modify or transmit any part of
          the Website.
        </p>

        <span>(b)</span>
        <p>
          The Website contains trademarks, logos, service names and trade names
          of “SuburbGrowth.com.au” or third parties which may be registered or
          otherwise protected by law. You are not permitted to use any
          trademarks, logos, service names, trade names or any other content or
          copies of the content appearing on the Website.
        </p>

        <h4>
          <strong>10.</strong>
          <strong>Prohibited uses</strong>
        </h4>

        <span>(a)</span>
        <p>
          Except as otherwise permitted in submitting Requests using the
          Website, in using the Website you must not:
        </p>
        <span>(i)</span>
        <p>
          engage in any commercial use or activity including marketing,
          advertising or commercial promotion of goods or services, resale,
          collect and use any product lists or information for the benefit of
          other merchants, data mine or use robots or other data collection
          methods;
        </p>
        <span>(ii)</span>
        <p>
          impersonate or falsely claim to represent a person or organisation;
        </p>
        <span>(iii)</span>
        <p>
          defame, abuse, stalk, harass, threaten or otherwise violate the legal
          rights of others, including without limitation, rights relating to
          privacy and publicity;
        </p>
        <span>(iv)</span>
        <p>
          post, link to, or otherwise communicate or distribute any
          inappropriate, profane, defamatory, infringing, obscene, indecent or
          unlawful material or information, or otherwise use the Website in a
          manner which is unlawful or would infringe the rights of another
          person including any intellectual property rights; or
        </p>
        <span>(v)</span>
        <p>
          post, link to, or otherwise distribute any information, material or
          item which contains a virus, trojan horse, worm or other harmful or
          disruptive component.
        </p>
        <span>(b)</span>
        <p>
          Unauthorised use of the Website may give rise to a claim for damages
          and/or may result in legal proceedings being taken against you.
        </p>

        <span>(c)</span>
        <p>
          “SuburbGrowth.com.au” provides no warranties and cannot guarantee that
          any file, program, access or use of the Website is free from viruses,
          malware or other harmful technology or material which could damage or
          infect your data, hardware, software or other equipment. By accessing
          and using the Website (including placing Requests through the Website)
          you assume all risk in this regard and you release
          “SuburbGrowth.com.au” from all applicable liability and
          responsibility.
        </p>

        <h4>
          <strong>11.</strong>
          <strong>Termination of your access to the Website</strong>
        </h4>
        <p>
          “SuburbGrowth.com.au” may at any time immediately terminate your
          access (including restricting access) to the Website or any feature of
          the Website for any reason (including due to your breach or alleged
          breach of these Terms) in its sole discretion and without prior
          notice. Any indemnities given by you and any limitations of our
          liability survive such termination.
        </p>
        <h4>
          <strong>12.</strong>
          <strong>Disclaimer of warranties and limitation of liability </strong>
        </h4>
        <span>(a)</span>
        <p>
          To the full extent permitted by law, “SuburbGrowth.com.au” excludes
          all warranties, whether express or implied, including any warranties
          or representations concerning availability of the Website, quality,
          completeness, accuracy, suitability, acceptability or fitness for
          purpose in relation to the Website, the Content, the conduct of any
          users, all links to or from the Website and the goods and services
          listed or accessible on the Website.
        </p>
        <span>(b)</span>
        <p>
          Subject to the consumer guarantees provided for in consumer protection
          legislation (including the Australian Consumer Law)
          “SuburbGrowth.com.au” excludes all liability for any loss, damage,
          claim, cost or expense whatsoever arising out of or in connection with
          these Terms, the Website, the Content, all links to or from the
          Website.
        </p>
        <h4>
          <strong>13.</strong>
          <strong>Indemnity</strong>
        </h4>
        <p>
          You agree to fully indemnify “SuburbGrowth.com.au”, its directors,
          officers, directors, employees, consultants, agents and affiliates in
          respect of all loss, damage, costs, expenses (including legal fees on
          a full indemnity basis), fines, penalties, claims, demands and
          proceedings howsoever arising, whether at common law (including
          negligence) or under statute, in connection with any of the following:
        </p>
        <span>(a)</span>
        <p>Any breach of these Terms by you;</p>
        <span>(b)</span>
        <p>Your access or use of the Website;</p>
        <span>(c)</span>
        <p>Any Request which you choose to submit on the Website; or</p>
        <h4>
          <strong>14.</strong>
          <strong>Jurisdiction and law</strong>
        </h4>
        <p>
          These Terms are governed by and must be construed in accordance with
          the laws of the State of Victoria, Australia. You submit to the
          exclusive jurisdiction of the courts of that State and the
          Commonwealth of Australia in respect of all matters arising out of or
          relating to these Terms, their performance and subject matter.
        </p>
        <h4>
          <strong>15.</strong>
          <strong>Waiver</strong>
        </h4>
        <p>
          If you breach these conditions and we take no action, we will still be
          entitled to use our rights and remedies in any other situation where
          you breach these Terms.
        </p>
        <h4>
          <strong>16.</strong>
          <strong>Severability</strong>
        </h4>
        <p>
          If the whole or any part of a provision of these Terms and Conditions
          are void, unenforceable or illegal in a jurisdiction it is severed for
          that jurisdiction. The remainder of the Terms and Conditions has full
          force and effect and the validity or enforceability of that provision
          in any other jurisdiction is not affected. This clause has no effect
          if the severance alters the basic nature of the Terms and Conditions
          or is contrary to public policy.
        </p>
        <h4>
          <strong>17.</strong>
          <strong>Entire Agreement</strong>
        </h4>
        <p>
          The above Terms constitute the entire agreement of the parties and
          supersede any and all preceding and contemporaneous agreements between
          you and “SuburbGrowth.com.au”. Any waiver of any provision of the
          Terms will be effective only if in writing and signed by a Director of
          “SuburbGrowth.com.au”.
        </p>
        <h4>
          <strong>18.</strong>
          <strong>Contacting us</strong>
        </h4>
        <p>
          If you have questions about the Website, the Terms or Privacy Policy,
          please contact us at{" "}
          <a href="mailto:info@suburbgrowth.com.au">info@suburbgrowth.com.au</a>
        </p>
      </div>
    </section>
  </SiteLayout>
)

export default TermsAndConditionsPage
